<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- Striped Rows -->
    <div class="col-xxl-9">
      <div class="card mt-xxl-n5">
        <table class="table table-striped">
          <thead>
              <tr>
                  <th scope="col">Id</th>

                  <th scope="col">Nom</th>

                  <th scope="col">Prenoms</th>

                  <th scope="col">Matières</th>

                  <th scope="col">statut</th>

                  <th scope="col">Action</th>

              </tr>

          </thead>

          <tbody>

              <tr>

                  <th scope="row">1</th>

                  <td>Bobby</td>

                  <td>Davis</td>

                  <td>Maths; Statistiques</td>

                  <td><span class="badge bg-success">Confirmed</span></td>

                  <td>
                      <div class="hstack gap-3 flex-wrap">
                          <a href="javascript:void(0);" class="link-success fs-15"><i class="ri-edit-2-line"></i></a>
                          <a href="javascript:void(0);" class="link-danger fs-15"><i class="ri-delete-bin-line"></i></a>
                      </div>
                  </td>

              </tr>

              <tr>

                  <th scope="row">2</th>

                  <td>Christopher </td>

                  <td>Neal</td>

                  <td>Français; Droit</td>

                  <td><span class="badge bg-warning">Waiting</span></td>

                  <td>
                      <div class="hstack gap-3 flex-wrap">
                          <a href="javascript:void(0);" class="link-success fs-15"><i class="ri-edit-2-line"></i></a>
                          <a href="javascript:void(0);" class="link-danger fs-15"><i class="ri-delete-bin-line"></i></a>
                      </div>
                  </td>
              </tr>
          </tbody>
        </table>
      </div>
    </div>
  </Layout>
</template>

<script>

import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header";

export default {
  setup() { },

  data() {
    return {
      title: "Liste",
      items: [
        {
          text: "Professeurs",
          href: "/",
        },
        {
          text: "En attente",
          active: true,
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  mounted() {},

  methods: {},
};
</script>

